import React from 'react';

interface IRhombusSmall_05 {
    fill: string;
    id?: string;
}

const RhombusSmall_05: React.FC<IRhombusSmall_05> = ({ fill, id }) => {
  return (
  <svg width="247" height="247" viewBox="0 0 247 247" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2171" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="247" height="247">
  <path d="M0.626221 123.627L123.627 246.627L246.627 123.627L123.627 0.626588L0.626221 123.627Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2171)">
  <rect x="2.00024" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="14.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="26.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="38.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="50.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="62.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="74.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="86.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="98.0002" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="110" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="122" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="134" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="146" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="158" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="170" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="182" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="194" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="206" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="218" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="230" y="-512.369" width="4" height="1272" fill={fill}/>
  <rect x="242" y="-512.369" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RhombusSmall_05;
