import React from 'react';

interface IRectangleSmall_10 {
    fill: string;
    id?: string;
}

const RectangleSmall_10: React.FC<IRectangleSmall_10> = ({ fill, id }) => {
  return (
  <svg width="232" height="91" viewBox="0 0 232 91" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1485" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="232" height="91">
  <path d="M0 91H232V5.96046e-08H0V91Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1485)">
  <rect x="16" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="40" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="64" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="88" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="112" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="136" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="160" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="184" y="-673" width="8" height="1437" fill={fill}/>
  <rect x="208" y="-673" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RectangleSmall_10;
