import React from 'react';

interface IRectangle_08 {
    fill: string;
    id?: string;
}

const Rectangle_08: React.FC<IRectangle_08> = ({ fill, id }) => {
  return (
  <svg width="368" height="173" viewBox="0 0 368 173" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_552" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="368" height="173">
  <path d="M0 173H368V-4.52995e-06H0V173Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_552)">
  <rect x="1" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="19" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="37" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="55" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="73" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="91" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="109" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="127" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="145" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="163" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="181" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="199" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="217" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="235" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="253" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="271" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="289" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="307" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="325" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="343" y="-641" width="6" height="1456" fill={fill}/>
  <rect x="361" y="-641" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rectangle_08;
