import React from 'react';

interface IRhombus_02 {
    fill: string;
    id?: string;
}

const Rhombus_02: React.FC<IRhombus_02> = ({ fill, id }) => {
  return (
  <svg width="422" height="422" viewBox="0 0 422 422" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1754_711" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="422" height="422">
  <path d="M0 211L211 422L422 211L211 -0.000229559L0 211Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1754_711)">
  <rect x="18.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="114.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="66.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="162.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="42.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="138.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="90.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="186.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="30.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="126.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="78.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="174.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="54.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="150.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="102.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="6.03711" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="198.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="24.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="120.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="72.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="168.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="48.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="144.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="96.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="0.0371094" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="192.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="36.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="132.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="84.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="180.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="60.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="156.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="108.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="12.0371" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="204.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="210.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="402.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="306.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="258.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="354.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="234.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="330.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="282.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="378.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="222.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="414.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="318.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="270.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="366.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="246.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="342.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="294.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="390.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="216.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="408.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="312.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="264.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="360.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="240.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="336.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="288.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="384.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="228.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="420.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="324.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="276.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="372.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="252.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="348.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="300.037" y="-501.978" width="2" height="1426" fill={fill}/>
  <rect x="396.037" y="-501.978" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rhombus_02;
