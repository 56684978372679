import React from 'react';

interface IRhombusSmall_15 {
    fill: string;
    id?: string;
}

const RhombusSmall_15: React.FC<IRhombusSmall_15> = ({ fill, id }) => {
  return (
  <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1847" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="300" height="300">
  <path d="M0 150L150 300L300 150L150 5.82422e-07L0 150Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1847)">
  <rect x="0.0112305" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="36.0112" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="72.0112" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="108.011" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="144.011" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="180.011" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="216.011" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="252.011" y="-831.348" width="12" height="1961" fill={fill}/>
  <rect x="288.011" y="-831.348" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RhombusSmall_15;
