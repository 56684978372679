import React from 'react';

interface IRhombus_05 {
    fill: string;
    id?: string;
}

const Rhombus_05: React.FC<IRhombus_05> = ({ fill, id }) => {
  return (
  <svg width="436" height="436" viewBox="0 0 436 436" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1814_564" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="437" height="437">
  <path d="M0.00158691 218.002L218.002 436.002L436.002 218.002L218.002 0.00175849L0.00158691 218.002Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1814_564)">
  <rect x="-0.000488281" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="11.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="23.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="35.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="47.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="59.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="71.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="83.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="95.9995" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="108" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="120" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="132" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="144" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="156" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="168" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="180" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="192" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="204" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="216" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="228" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="240" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="252" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="264" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="276" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="288" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="300" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="312" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="324" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="336" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="348" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="360" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="372" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="384" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="396" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="408" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="420" y="-417.994" width="4" height="1272" fill={fill}/>
  <rect x="432" y="-417.994" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rhombus_05;
