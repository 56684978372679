import React from 'react';

interface ISquare_08 {
    fill: string;
    id?: string;
}

const Square_08: React.FC<ISquare_08> = ({ fill, id }) => {
  return (
  <svg width="344" height="344" viewBox="0 0 344 344" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_456" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="344" height="344">
  <path d="M0 344H344V-1.00732e-05H0V344Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_456)">
  <rect x="7" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="25" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="43" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="61" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="79" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="97" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="115" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="133" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="151" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="169" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="187" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="205" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="223" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="241" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="259" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="277" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="295" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="313" y="-556" width="6" height="1456" fill={fill}/>
  <rect x="331" y="-556" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Square_08;
