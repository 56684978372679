import React from 'react';

interface ICircle_05 {
    fill: string;
    id?: string;
}

const Circle_05: React.FC<ICircle_05> = ({ fill, id }) => {
  return (
  <svg width="460" height="465" viewBox="0 0 460 465" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1814_745" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="460" height="465">
  <ellipse cx="230" cy="232.5" rx="230" ry="232.5" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1814_745)">
  <rect y="-398" width="4" height="1272" fill={fill}/>
  <rect x="12" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="24" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="36" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="48" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="60" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="72" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="84" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="96" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="108" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="120" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="132" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="144" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="156" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="168" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="180" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="192" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="204" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="216" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="228" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="240" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="252" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="264" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="276" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="288" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="300" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="312" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="324" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="336" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="348" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="360" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="372" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="384" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="396" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="408" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="420" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="432" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="444" y="-398" width="4" height="1272" fill={fill}/>
  <rect x="456" y="-398" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Circle_05;
