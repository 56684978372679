import React from 'react';

interface ICircleSmall_08 {
  fill: string;
  id?: string;
}

const CircleSmall_08: React.FC<ICircleSmall_08> = ({ fill, id }) => {
  return (
  <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_990" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="240" height="240">
  <circle cx="120" cy="120" r="120" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_990)">
  <rect y="-609" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-609" width="6" height="1456" fill={fill}/>
  <rect x="234" y="-609" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default CircleSmall_08;
