import React from 'react';

interface IInvertedTriangleSmall_15 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_15: React.FC<IInvertedTriangleSmall_15> = ({ fill, id }) => {
  return (
  <svg width="228" height="230" viewBox="0 0 228 230" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1811" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="228" height="230">
  <path d="M114 230L228 4.84355e-06L0 4.84355e-06L114 230Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1811)">
  <rect y="-865" width="12" height="1961" fill={fill}/>
  <rect x="36" y="-865" width="12" height="1961" fill={fill}/>
  <rect x="72" y="-865" width="12" height="1961" fill={fill}/>
  <rect x="108" y="-865" width="12" height="1961" fill={fill}/>
  <rect x="144" y="-865" width="12" height="1961" fill={fill}/>
  <rect x="180" y="-865" width="12" height="1961" fill={fill}/>
  <rect x="216" y="-865" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangleSmall_15;
