import React from 'react';

interface ICircle_08 {
    fill: string;
    id?: string;
}

const Circle_08: React.FC<ICircle_08> = ({ fill, id }) => {
  return (
  <svg width="472" height="472" viewBox="0 0 472 472" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_649" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="472" height="472">
  <circle cx="236" cy="236" r="236" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_649)">
  <rect x="-1" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="17" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="35" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="53" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="71" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="89" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="107" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="125" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="143" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="161" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="179" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="197" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="215" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="233" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="251" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="269" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="287" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="305" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="323" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="341" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="359" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="377" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="395" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="413" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="431" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="449" y="-474" width="6" height="1456" fill={fill}/>
  <rect x="467" y="-474" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
    );
    };
    export default Circle_08;
