import React from 'react';

interface IRhombus_08 {
    fill: string;
    id?: string;
}

const Rhombus_08: React.FC<IRhombus_08> = ({ fill, id }) => {
  return (
  <svg width="438" height="438" viewBox="0 0 438 438" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_504" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="439" height="439">
  <path d="M0.00146484 219.002L219.002 438.002L438.002 219.002L219.002 0.00163731L0.00146484 219.002Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_504)">
  <rect y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="234" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="252" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="270" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="288" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="306" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="324" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="342" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="360" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="378" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="396" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="414" y="-508.991" width="6" height="1456" fill={fill}/>
  <rect x="432" y="-508.991" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rhombus_08;
