import React from 'react';

interface ITriangleSmall_10 {
    fill: string;
    id?: string;
}

const TriangleSmall_10: React.FC<ITriangleSmall_10> = ({ fill, id }) => {
  return (
  <svg width="243" height="244" viewBox="0 0 243 244" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1376" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="243" height="244">
  <path d="M121.5 -1.06219e-05L-2.00199e-05 244L243 244L121.5 -1.06219e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1376)">
  <rect x="-2" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="22" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="46" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="70" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="94" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="118" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="142" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="166" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="190" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="214" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="238" y="-596" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default TriangleSmall_10;
