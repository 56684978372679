import React from 'react';

interface IInvertedTriangleSmall_05 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_05: React.FC<IInvertedTriangleSmall_05> = ({ fill, id }) => {
  return (
  <svg width="219" height="220" viewBox="0 0 219 220" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2111" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="219" height="220">
  <path d="M109.5 220L219 -9.5808e-06L0 -9.5808e-06L109.5 220Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2111)">
  <rect x="-1" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="11" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="23" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="35" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="47" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="59" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="71" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="83" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="95" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="107" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="119" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="131" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="143" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="155" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="167" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="179" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="191" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="203" y="-526" width="4" height="1272" fill={fill}/>
  <rect x="215" y="-526" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangleSmall_05;
