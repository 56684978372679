import React from 'react';

interface ICircleSmall_02 {
  fill: string;
  id?: string;
}

const CircleSmall_02: React.FC<ICircleSmall_02> = ({ fill, id }) => {
  return (
  <svg width="164" height="162" viewBox="0 0 164 162" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1782_1502" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="164" height="162">
  <ellipse cx="82" cy="81" rx="82" ry="81" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1782_1502)">
  <rect x="36" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="12" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="60" y="-632" width="2" height="1426" fill={fill}/>
  <rect y="-632" width="2" height="1426" fill={fill}/>
  <rect x="48" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="24" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="72" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="42" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="18" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="66" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="6" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="54" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="30" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="78" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="84" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="132" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="108" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="156" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="96" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="144" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="120" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="90" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="138" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="114" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="162" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="102" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="150" y="-632" width="2" height="1426" fill={fill}/>
  <rect x="126" y="-632" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default CircleSmall_02;
