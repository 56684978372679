import React from 'react';

interface ISquare_10 {
    fill: string;
    id?: string;
}

const Square_10: React.FC<ISquare_10> = ({ fill, id }) => {
  return (
  <svg width="344" height="344" viewBox="0 0 344 344" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1160" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="344" height="344">
  <path d="M0 344H344V-1.00732e-05H0V344Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1160)">
  <rect y="-546" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-546" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-546" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Square_10;
