import React from 'react';

interface IEllipse_15 {
    fill: string;
    id?: string;
}

const Ellipse_15: React.FC<IEllipse_15> = ({ fill, id }) => {
  return (
  <svg width="516" height="352" viewBox="0 0 516 352" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1703" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="516" height="352">
  <ellipse cx="258" cy="176" rx="258" ry="176" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1703)">
  <rect y="-804" width="12" height="1961" fill={fill}/>
  <rect x="36" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="72" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="108" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="144" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="180" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="216" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="252" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="288" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="324" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="360" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="396" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="432" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="468" y="-804" width="12" height="1961" fill={fill}/>
  <rect x="504" y="-804" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Ellipse_15;
