import React from 'react';

interface ITriangleSmall_02 {
    fill: string;
    id?: string;
}

const TriangleSmall_02: React.FC<ITriangleSmall_02> = ({ fill, id }) => {
  return (
  <svg width="243" height="244" viewBox="0 0 243 244" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1781_585" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="243" height="244">
  <path d="M121.5 -1.06219e-05L6.3254e-06 244L243 244L121.5 -1.06219e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1781_585)">
  <rect x="25" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="73" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="49" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="1" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="97" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="37" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="85" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="61" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="13" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="109" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="31" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="79" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="55" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="7" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="103" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="43" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="91" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="67" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="19" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="115" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="121" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="217" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="169" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="145" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="241" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="193" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="133" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="229" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="181" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="157" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="205" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="127" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="223" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="175" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="151" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="199" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="139" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="235" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="187" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="163" y="-591" width="2" height="1426" fill={fill}/>
  <rect x="211" y="-591" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default TriangleSmall_02;
