import React from 'react';

interface IRectangleSmall_15 {
    fill: string;
    id?: string;
}

const RectangleSmall_15: React.FC<IRectangleSmall_15> = ({ fill, id }) => {
  return (
  <svg width="232" height="91" viewBox="0 0 232 91" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1919" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="232" height="91">
  <path d="M0 91H232V5.96046e-08H0V91Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1919)">
  <rect x="2" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="38" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="74" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="110" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="146" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="182" y="-935" width="12" height="1961" fill={fill}/>
  <rect x="218" y="-935" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RectangleSmall_15;
