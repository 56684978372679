import React from 'react';

interface IRectangleSmall_05 {
    fill: string;
    id?: string;
}

const RectangleSmall_05: React.FC<IRectangleSmall_05> = ({ fill, id }) => {
  return (
  <svg width="232" height="91" viewBox="0 0 232 91" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2231" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="232" height="91">
  <path d="M0 91H232V5.96046e-08H0V91Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2231)">
  <rect x="6" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="18" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="30" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="42" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="54" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="66" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="78" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="90" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="102" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="114" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="126" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="138" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="150" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="162" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="174" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="186" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="198" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="210" y="-590" width="4" height="1272" fill={fill}/>
  <rect x="222" y="-590" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RectangleSmall_05;
