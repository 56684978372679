import React from 'react';

interface ICircle_02 {
    fill: string;
    id?: string;
}

const Circle_02: React.FC<ICircle_02> = ({ fill, id }) => {
  return (
  <svg width="447" height="447" viewBox="0 0 447 447" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1759_443" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="447" height="447">
  <path d="M447 223.5C447 100.064 346.936 1.14441e-05 223.5 1.14441e-05C100.064 1.14441e-05 0 100.064 0 223.5C0 346.936 100.064 447 223.5 447C346.936 447 447 346.936 447 223.5Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1759_443)">
  <rect x="30" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="126" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="78" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="174" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="54" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="150" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="102" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="6" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="198" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="42" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="138" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="90" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="186" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="66" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="162" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="114" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="18" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="210" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="36" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="132" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="84" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="180" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="60" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="156" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="108" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="12" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="204" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="48" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="144" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="96" y="-486" width="2" height="1426" fill={fill}/>
  <rect y="-486" width="2" height="1426" fill={fill}/>
  <rect x="192" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="72" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="168" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="120" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="24" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="216" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="222" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="414" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="318" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="270" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="366" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="246" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="438" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="342" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="294" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="390" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="234" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="426" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="330" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="282" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="378" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="258" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="354" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="306" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="402" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="228" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="420" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="324" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="276" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="372" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="252" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="444" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="348" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="300" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="396" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="240" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="432" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="336" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="288" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="384" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="264" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="360" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="312" y="-486" width="2" height="1426" fill={fill}/>
  <rect x="408" y="-486" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Circle_02;
