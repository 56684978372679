import React from 'react';

interface IRectangle_02 {
    fill: string;
    id?: string;
}

const Rectangle_02: React.FC<IRectangle_02> = ({ fill, id }) => {
  return (
  <svg width="368" height="173" viewBox="0 0 368 173" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1754_1110" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="368" height="173">
  <path d="M0 173H368V-4.52995e-06H0V173Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1754_1110)">
  <rect x="24" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="120" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="72" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="168" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="48" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="144" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="96" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="192" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="36" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="132" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="84" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="180" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="60" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="156" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="108" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="12" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="204" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="30" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="126" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="78" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="174" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="54" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="150" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="102" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="6" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="198" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="42" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="138" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="90" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="186" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="66" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="162" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="114" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="18" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="210" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="216" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="312" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="264" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="360" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="240" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="336" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="288" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="228" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="324" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="276" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="252" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="348" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="300" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="222" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="318" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="270" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="366" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="246" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="342" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="294" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="234" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="330" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="282" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="258" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="354" y="-546.118" width="2" height="1258.24" fill={fill}/>
  <rect x="306" y="-546.118" width="2" height="1258.24" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rectangle_02;
