import React from 'react';

interface IEllipseSmall_15 {
    fill: string;
    id?: string;
}

const EllipseSmall_15: React.FC<IEllipseSmall_15> = ({ fill, id }) => {
  return (
  <svg width="228" height="160" viewBox="0 0 228 160" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1955" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="228" height="160">
  <ellipse cx="114" cy="80" rx="114" ry="80" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1955)">
  <rect y="-880" width="12" height="1961" fill={fill}/>
  <rect x="36" y="-880" width="12" height="1961" fill={fill}/>
  <rect x="72" y="-880" width="12" height="1961" fill={fill}/>
  <rect x="108" y="-880" width="12" height="1961" fill={fill}/>
  <rect x="144" y="-880" width="12" height="1961" fill={fill}/>
  <rect x="180" y="-880" width="12" height="1961" fill={fill}/>
  <rect x="216" y="-880" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default EllipseSmall_15;
