import React from 'react';
import { IShapeButtonPaletteParams } from './IShapeButtonPaletteParams';
import './ShapeButtonPalette.css';
import { useTranslation } from 'react-i18next';

export function ShapeButtonPalette(params: IShapeButtonPaletteParams) {
    const { t } = useTranslation();
    let shapesIconArr: any[] = [];
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        e.preventDefault();
      };
    const handleContextMenu = (e: React.MouseEvent<HTMLImageElement>) => {
        e.preventDefault();
    };
    params.shapePalette.forEach((shape, i) => {
        shapesIconArr[i] = require(`./../../../assets/img/shapes/${shape.fileName}`);
    });
    
    let shapesList = params.shapePalette.map((shape, i) =>
        <>
            {shape.name === "hidden" ? null : 
                <img onDragStart={handleDragStart} onContextMenu={handleContextMenu} key={shape.name} src={shapesIconArr[i].default} alt={`${shape.title} icon`} onClick={() => { params.onShapeChange(shape); }}
                className="shape-button-palette__button" title={t(shape.title)} />
            }
        </>
    );

    const gradientDecoration = require('./../../../assets/img/gradient.png');
    
    return <div className="shape-button-palette__wrapper">
        <img onDragStart={handleDragStart} onContextMenu={handleContextMenu} className="shape-button-palette__image" src={gradientDecoration.default} alt="Gradient decoration" />
        {shapesList}
    </div>;
}
