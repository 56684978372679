import React from 'react';

interface ISquareSmall_05 {
    fill: string;
    id?: string;
}

const SquareSmall_05: React.FC<ISquareSmall_05> = ({ fill, id }) => {
  return (
  <svg width="181" height="180" viewBox="0 0 181 180" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2542" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="181" height="180">
  <path d="M0 180H181V-5.00679e-06H0V180Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2542)">
  <rect x="4" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="16" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="28" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="40" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="52" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="64" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="76" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="88" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="100" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="112" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="124" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="136" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="148" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="160" y="-546" width="4" height="1272" fill={fill}/>
  <rect x="172" y="-546" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default SquareSmall_05;
