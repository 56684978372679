import React from 'react';

interface IRectangleSmall_08 {
    fill: string;
    id?: string;
}

const RectangleSmall_08: React.FC<IRectangleSmall_08> = ({ fill, id }) => {
  return (
  <svg width="232" height="91" viewBox="0 0 232 91" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_894" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="232" height="91">
  <path d="M0 91H232V5.96046e-08H0V91Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_894)">
  <rect x="5" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="23" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="41" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="59" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="77" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="95" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="113" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="131" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="149" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="167" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="185" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="203" y="-682" width="6" height="1456" fill={fill}/>
  <rect x="221" y="-682" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RectangleSmall_08;
