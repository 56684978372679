import React from 'react';

interface ITriangleSmall_15 {
    fill: string;
    id?: string;
}

  const TriangleSmall_15: React.FC<ITriangleSmall_15> = ({ fill, id }) => {
  return (
  <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1775" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="300" height="300">
  <path d="M150 -1.31134e-05L-8.34544e-06 300L300 300L150 -1.31134e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1775)">
  <rect y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="36" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="72" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="108" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="144" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="180" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="216" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="252" y="-830.999" width="12" height="1961" fill={fill}/>
  <rect x="288" y="-830.999" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default TriangleSmall_15;
