import React from 'react';

interface IRhombusSmall_10 {
    fill: string;
    id?: string;
}

const RhombusSmall_10: React.FC<IRhombusSmall_10> = ({ fill, id }) => {
  return (
  <svg width="248" height="248" viewBox="0 0 248 248" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1833_348" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="248">
  <path d="M0 124L124 248L248 124L124 -1.21508e-05L0 124Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1833_348)">
  <rect y="-595" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-595" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-595" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RhombusSmall_10;
