import React from 'react';

interface ICircle_15 {
    fill: string;
    id?: string;
}

const Circle_15: React.FC<ICircle_15> = ({ fill, id }) => {
  return (
  <svg width="443" height="443" viewBox="0 0 443 443" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1739" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="443" height="443">
  <circle cx="221.5" cy="221.5" r="221.5" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1739)">
  <rect x="-1" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="35" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="71" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="107" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="143" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="179" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="215" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="251" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="287" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="323" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="359" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="395" y="-759" width="12" height="1961" fill={fill}/>
  <rect x="431" y="-759" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Circle_15;
