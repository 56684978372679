import { IFooterIconParams } from "./IFooterIconParams";

export function FooterIcon(params: IFooterIconParams) {
    const icon = require(`./../../../${params.imagePath}`);
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        e.preventDefault();
      };
    const handleContextMenu = (e: React.MouseEvent<HTMLImageElement>) => {
        e.preventDefault();
    };
    return <img onDragStart={handleDragStart} onContextMenu={handleContextMenu} className={params.class} src={icon.default} alt={params.alt} 
                title={(typeof params.title != 'undefined' ? params.title : '')} onClick={() => { params.onClickAction(); }} />;
}