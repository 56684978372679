import React from 'react';

interface ITriangle_02 {
    fill: string;
    id?: string;
}

const Triangle_02: React.FC<ITriangle_02> = ({ fill, id }) => {
  return (
  <svg width="398" height="399" viewBox="0 0 398 399" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1754_973" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="398" height="399">
  <path d="M199 -1.73971e-05L-1.1159e-05 399L398 399L199 -1.73971e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1754_973)">
  <rect x="90" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="42" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="138" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="18" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="114" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="66" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="162" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="6" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="102" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="54" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="150" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="30" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="126" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="78" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="174" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="96" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="48" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="144" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="24" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="120" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="72" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="168" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="12" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="108" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="60" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="156" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="36" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="132" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="84" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="180" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="186" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="378" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="282" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="234" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="330" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="210" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="306" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="258" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="354" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="198" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="390" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="294" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="246" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="342" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="222" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="318" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="270" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="366" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="192" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="384" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="288" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="240" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="336" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="216" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="312" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="264" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="360" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="204" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="396" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="300" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="252" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="348" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="228" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="324" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="276" y="-498.998" width="2" height="1426" fill={fill}/>
  <rect x="372" y="-498.998" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Triangle_02;
