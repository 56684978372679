import React from 'react';

interface IInvertedTriangle_15 {
    fill: string;
    id?: string;
}

const InvertedTriangle_15: React.FC<IInvertedTriangle_15> = ({ fill, id }) => {
  return (
  <svg width="374" height="377" viewBox="0 0 374 377" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1594" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="374" height="377">
  <path d="M187 377L374 -1.41395e-05L0 -1.41395e-05L187 377Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1594)">
  <rect x="1" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="37" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="73" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="109" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="145" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="181" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="217" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="253" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="289" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="325" y="-792" width="12" height="1961" fill={fill}/>
  <rect x="361" y="-792" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangle_15;
