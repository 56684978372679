import React from 'react';

interface ISquare_05 {
    fill: string;
    id?: string;
}

const Square_05: React.FC<ISquare_05> = ({ fill, id }) => {
  return (
  <svg width="344" height="344" viewBox="0 0 344 344" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1814_936" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="344" height="344">
  <path d="M0 344H344V0H0V344Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1814_936)">
  <rect x="2" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="14" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="26" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="38" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="50" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="62" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="74" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="86" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="98" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="110" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="122" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="134" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="146" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="158" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="170" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="182" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="194" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="206" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="218" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="230" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="242" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="254" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="266" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="278" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="290" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="302" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="314" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="326" y="-464" width="4" height="1272" fill={fill}/>
  <rect x="338" y="-464" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Square_05;
