import React from 'react';

interface ICircleSmall_05 {
  fill: string;
  id?: string;
}

const CircleSmall_05: React.FC<ICircleSmall_05> = ({ fill, id }) => {
  return (
  <svg width="219" height="217" viewBox="0 0 219 217" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2482" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="219" height="217">
  <ellipse cx="109.5" cy="108.5" rx="109.5" ry="108.5" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2482)">
  <rect x="-1" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="11" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="23" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="35" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="47" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="59" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="71" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="83" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="95" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="107" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="119" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="131" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="143" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="155" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="167" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="179" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="191" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="203" y="-527" width="4" height="1272" fill={fill}/>
  <rect x="215" y="-527" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default CircleSmall_05;
