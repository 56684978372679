import React from 'react';

interface IEllipseSmall_10 {
    fill: string;
    id?: string;
}

const EllipseSmall_10: React.FC<IEllipseSmall_10> = ({ fill, id }) => {
  return (
  <svg width="248" height="160" viewBox="0 0 248 160" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1521" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="160">
  <ellipse cx="124" cy="80" rx="124" ry="80" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1521)">
  <rect y="-638" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-638" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-638" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default EllipseSmall_10;
