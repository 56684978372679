import React from 'react';

interface IEllipse_05 {
    fill: string;
    id?: string;
}

const Ellipse_05: React.FC<IEllipse_05> = ({ fill, id }) => {
  return (
  <svg width="484" height="358" viewBox="0 0 484 358" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1814_685" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="484" height="358">
  <ellipse cx="242" cy="179" rx="242" ry="179" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1814_685)">
  <rect y="-457" width="4" height="1272" fill={fill}/>
  <rect x="12" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="24" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="36" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="48" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="60" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="72" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="84" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="96" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="108" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="120" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="132" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="144" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="156" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="168" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="180" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="192" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="204" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="216" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="228" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="240" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="252" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="264" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="276" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="288" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="300" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="312" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="324" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="336" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="348" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="360" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="372" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="384" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="396" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="408" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="420" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="432" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="444" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="456" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="468" y="-457" width="4" height="1272" fill={fill}/>
  <rect x="480" y="-457" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Ellipse_05;
