import React from 'react';

interface IEllipse_10 {
    fill: string;
    id?: string;
}

const Ellipse_10: React.FC<IEllipse_10> = ({ fill, id }) => {
  return (
  <svg width="488" height="360" viewBox="0 0 488 360" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1268" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="488" height="360">
  <ellipse cx="244" cy="180" rx="244" ry="180" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1268)">
  <rect y="-538" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="360" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="384" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="408" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="432" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="456" y="-538" width="8" height="1437" fill={fill}/>
  <rect x="480" y="-538" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Ellipse_10;
