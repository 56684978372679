import React from 'react';

interface IEllipseSmall_08 {
    fill: string;
    id?: string;
}

const EllipseSmall_08: React.FC<IEllipseSmall_08> = ({ fill, id }) => {
  return (
  <svg width="222" height="160" viewBox="0 0 222 160" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_942" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="222" height="160">
  <ellipse cx="111" cy="80" rx="111" ry="80" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_942)">
  <rect y="-648" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-648" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-648" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default EllipseSmall_08;
