import React from 'react';

interface IInvertedTriangle_08 {
    fill: string;
    id?: string;
}

const InvertedTriangle_08: React.FC<IInvertedTriangle_08> = ({ fill, id }) => {
  return (
  <svg width="402" height="403" viewBox="0 0 402 403" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_408" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="402" height="403">
  <path d="M201 403L402 5.92827e-06L0 5.92827e-06L201 403Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_408)">
  <rect y="-526" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="234" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="252" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="270" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="288" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="306" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="324" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="342" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="360" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="378" y="-526" width="6" height="1456" fill={fill}/>
  <rect x="396" y="-526" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangle_08;
