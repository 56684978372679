import React from 'react';

interface ITriangleSmall_05 {
    fill: string;
    id?: string;
}

const TriangleSmall_05: React.FC<ITriangleSmall_05> = ({ fill, id }) => {
  return (
  <svg width="245" height="246" viewBox="0 0 245 246" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2051" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="245" height="246">
  <path d="M122.5 -1.07093e-05L6.26976e-06 246L245 246L122.5 -1.07093e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2051)">
  <rect x="1" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="13" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="25" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="37" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="49" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="61" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="73" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="85" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="97" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="109" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="121" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="133" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="145" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="157" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="169" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="181" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="193" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="205" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="217" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="229" y="-513" width="4" height="1272" fill={fill}/>
  <rect x="241" y="-513" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default TriangleSmall_05;
