import React from 'react';

interface IInvertedTriangleSmall_02 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_02: React.FC<IInvertedTriangleSmall_02> = ({ fill, id }) => {
  return (
  <svg width="230" height="232" viewBox="0 0 230 232" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1811_313" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="230" height="232">
  <path d="M115 232L230 -8.72265e-06L4.05642e-05 -4.89371e-05L115 232Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1811_313)">
  <rect x="212" y="829" width="2" height="1426" transform="rotate(-180 212 829)" fill={fill}/>
  <rect x="164" y="829" width="2" height="1426" transform="rotate(-180 164 829)" fill={fill}/>
  <rect x="188" y="829" width="2" height="1426" transform="rotate(-180 188 829)" fill={fill}/>
  <rect x="140" y="829" width="2" height="1426" transform="rotate(-180 140 829)" fill={fill}/>
  <rect x="200" y="829" width="2" height="1426" transform="rotate(-180 200 829)" fill={fill}/>
  <rect x="152" y="829" width="2" height="1426" transform="rotate(-180 152 829)" fill={fill}/>
  <rect x="176" y="829" width="2" height="1426" transform="rotate(-180 176 829)" fill={fill}/>
  <rect x="224" y="829" width="2" height="1426" transform="rotate(-180 224 829)" fill={fill}/>
  <rect x="128" y="829" width="2" height="1426" transform="rotate(-180 128 829)" fill={fill}/>
  <rect x="206" y="829" width="2" height="1426" transform="rotate(-180 206 829)" fill={fill}/>
  <rect x="158" y="829" width="2" height="1426" transform="rotate(-180 158 829)" fill={fill}/>
  <rect x="182" y="829" width="2" height="1426" transform="rotate(-180 182 829)" fill={fill}/>
  <rect x="230" y="829" width="2" height="1426" transform="rotate(-180 230 829)" fill={fill}/>
  <rect x="134" y="829" width="2" height="1426" transform="rotate(-180 134 829)" fill={fill}/>
  <rect x="194" y="829" width="2" height="1426" transform="rotate(-180 194 829)" fill={fill}/>
  <rect x="146" y="829" width="2" height="1426" transform="rotate(-180 146 829)" fill={fill}/>
  <rect x="170" y="829" width="2" height="1426" transform="rotate(-180 170 829)" fill={fill}/>
  <rect x="218" y="829" width="2" height="1426" transform="rotate(-180 218 829)" fill={fill}/>
  <rect x="122" y="829" width="2" height="1426" transform="rotate(-180 122 829)" fill={fill}/>
  <rect x="116" y="829" width="2" height="1426" transform="rotate(-180 116 829)" fill={fill}/>
  <rect x="20" y="829" width="2" height="1426" transform="rotate(-180 20 829)" fill={fill}/>
  <rect x="68" y="829" width="2" height="1426" transform="rotate(-180 68 829)" fill={fill}/>
  <rect x="92" y="829" width="2" height="1426" transform="rotate(-180 92 829)" fill={fill}/>
  <rect x="44" y="829" width="2" height="1426" transform="rotate(-180 44 829)" fill={fill}/>
  <rect x="104" y="829" width="2" height="1426" transform="rotate(-180 104 829)" fill={fill}/>
  <rect x="8" y="829" width="2" height="1426" transform="rotate(-180 8 829)" fill={fill}/>
  <rect x="56" y="829" width="2" height="1426" transform="rotate(-180 56 829)" fill={fill}/>
  <rect x="80" y="829" width="2" height="1426" transform="rotate(-180 80 829)" fill={fill}/>
  <rect x="32" y="829" width="2" height="1426" transform="rotate(-180 32 829)" fill={fill}/>
  <rect x="110" y="829" width="2" height="1426" transform="rotate(-180 110 829)" fill={fill}/>
  <rect x="14" y="829" width="2" height="1426" transform="rotate(-180 14 829)" fill={fill}/>
  <rect x="62" y="829" width="2" height="1426" transform="rotate(-180 62 829)" fill={fill}/>
  <rect x="86" y="829" width="2" height="1426" transform="rotate(-180 86 829)" fill={fill}/>
  <rect x="38" y="829" width="2" height="1426" transform="rotate(-180 38 829)" fill={fill}/>
  <rect x="98" y="829" width="2" height="1426" transform="rotate(-180 98 829)" fill={fill}/>
  <rect x="2" y="829" width="2" height="1426" transform="rotate(-180 2 829)" fill={fill}/>
  <rect x="50" y="829" width="2" height="1426" transform="rotate(-180 50 829)" fill={fill}/>
  <rect x="74" y="829" width="2" height="1426" transform="rotate(-180 74 829)" fill={fill}/>
  <rect x="26" y="829" width="2" height="1426" transform="rotate(-180 26 829)" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangleSmall_02;
