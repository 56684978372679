import React from 'react';

interface IRhombus_10 {
    fill: string;
    id?: string;
}

const Rhombus_10: React.FC<IRhombus_10> = ({ fill, id }) => {
  return (
  <svg width="440" height="440" viewBox="0 0 440 440" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1196" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="441" height="441">
  <path d="M0.00158691 220.002L220.002 440.002L440.002 220.002L220.002 0.001757L0.00158691 220.002Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1196)">
  <rect x="-0.00012207" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="23.9999" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="47.9999" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="71.9999" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="95.9999" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="360" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="384" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="408" y="-497.996" width="8" height="1437" fill={fill}/>
  <rect x="432" y="-497.996" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rhombus_10;
