import React from 'react';

interface IInvertedTriangle_05 {
    fill: string;
    id?: string;
}

const InvertedTriangle_05: React.FC<IInvertedTriangle_05> = ({ fill, id }) => {
  return (
  <svg width="388" height="389" viewBox="0 0 388 389" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1051" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="388" height="389">
  <path d="M194 389L388 1.18875e-05L0 1.18875e-05L194 389Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1051)">
  <rect y="-441" width="4" height="1272" fill={fill}/>
  <rect x="12" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="24" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="36" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="48" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="60" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="72" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="84" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="96" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="108" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="120" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="132" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="144" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="156" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="168" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="180" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="192" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="204" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="216" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="228" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="240" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="252" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="264" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="276" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="288" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="300" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="312" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="324" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="336" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="348" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="360" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="372" y="-441" width="4" height="1272" fill={fill}/>
  <rect x="384" y="-441" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangle_05;
