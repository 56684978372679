import React from 'react';

interface IRhombusSmall_08 {
    fill: string;
    id?: string;
}

const RhombusSmall_08: React.FC<IRhombusSmall_08> = ({ fill, id }) => {
  return (
  <svg width="254" height="254" viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1740_1082" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="254" height="254">
  <path d="M0 126.572L126.572 253.144L253.144 126.572L126.572 -9.01818e-05L0 126.572Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1740_1082)">
  <rect x="-2" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="16" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="34" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="52" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="70" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="88" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="106" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="124" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="142" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="160" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="178" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="196" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="214" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="232" y="-601.428" width="6" height="1456" fill={fill}/>
  <rect x="250" y="-601.428" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RhombusSmall_08;
