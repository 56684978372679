import React from 'react';

interface IRhombusSmall_02 {
    fill: string;
    id?: string;
}

const RhombusSmall_02: React.FC<IRhombusSmall_02> = ({ fill, id }) => {
  return (
  <svg width="207" height="207" viewBox="0 0 207 207" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1782_1109" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="207" height="207">
  <path d="M0.25293 103.254L103.253 206.254L206.254 103.254L103.253 0.25354L0.25293 103.254Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1782_1109)">
  <rect x="6.61914" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="54.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="30.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="78.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="18.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="66.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="42.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="90.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="12.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="60.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="36.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="84.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="24.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="72.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="48.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="0.619141" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="96.6191" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="102.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="198.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="150.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="126.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="174.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="114.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="162.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="138.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="186.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="108.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="204.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="156.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="132.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="180.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="120.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="168.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="144.619" y="-609.822" width="2" height="1426" fill={fill}/>
  <rect x="192.619" y="-609.822" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default RhombusSmall_02;
