import React from 'react';

interface IInvertedTriangleSmall_10 {
    fill: string;
    id?: string;
}

const InvertedTriangleSmall_10: React.FC<IInvertedTriangleSmall_10> = ({ fill, id }) => {
  return (
  <svg width="248" height="250" viewBox="0 0 248 250" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1340" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="250">
  <path d="M124 250L248 -9.89662e-06L0 -9.89662e-06L124 250Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1340)">
  <rect y="-593" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-593" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-593" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangleSmall_10;
