import React from 'react';

interface ISquareSmall_10 {
    fill: string;
    id?: string;
}

const SquareSmall_10: React.FC<ISquareSmall_10> = ({ fill, id }) => {
  return (
  <svg width="181" height="180" viewBox="0 0 181 180" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1412" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="181" height="180">
  <path d="M0 180H181V-5.00679e-06H0V180Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1412)">
  <rect x="15" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="39" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="63" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="87" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="111" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="135" y="-628" width="8" height="1437" fill={fill}/>
  <rect x="159" y="-628" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default SquareSmall_10;
