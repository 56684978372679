import React from 'react';

interface IEllipse_02 {
    fill: string;
    id?: string;
}

const Ellipse_02: React.FC<IEllipse_02> = ({ fill, id }) => {
  return (
  <svg width="476" height="352" viewBox="0 0 476 352" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1754_1241" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="476" height="352">
  <path d="M476 176C476 78.7979 369.444 3.8147e-06 238 3.8147e-06C106.556 3.8147e-06 0 78.7979 0 176C0 273.202 106.556 352 238 352C369.444 352 476 273.202 476 176Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1754_1241)">
  <rect x="48" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="144" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="96" y="-534" width="2" height="1426" fill={fill}/>
  <rect y="-534" width="2" height="1426" fill={fill}/>
  <rect x="192" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="72" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="168" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="120" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="24" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="216" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="60" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="156" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="108" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="12" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="204" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="84" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="180" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="132" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="36" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="228" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="54" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="150" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="102" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="6" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="198" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="78" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="174" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="126" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="30" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="222" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="66" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="162" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="114" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="18" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="210" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="90" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="186" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="138" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="42" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="234" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="240" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="432" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="336" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="288" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="384" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="264" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="456" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="360" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="312" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="408" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="252" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="444" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="348" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="300" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="396" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="276" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="468" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="372" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="324" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="420" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="246" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="438" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="342" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="294" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="390" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="270" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="462" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="366" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="318" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="414" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="258" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="450" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="354" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="306" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="402" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="282" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="474" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="378" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="330" y="-534" width="2" height="1426" fill={fill}/>
  <rect x="426" y="-534" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Ellipse_02;
