import React from 'react';

interface ISquare_15 {
    fill: string;
    id?: string;
}

const Square_15: React.FC<ISquare_15> = ({ fill, id }) => {
  return (
  <svg width="344" height="344" viewBox="0 0 344 344" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1630" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="344" height="344">
  <path d="M0 344H344V-1.00732e-05H0V344Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1630)">
  <rect x="22" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="58" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="94" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="130" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="166" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="202" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="238" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="274" y="-808" width="12" height="1961" fill={fill}/>
  <rect x="310" y="-808" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Square_15;
