import React from 'react';

interface ITriangle_15 {
    fill: string;
    id?: string;
}

  const Triangle_15: React.FC<ITriangle_15> = ({ fill, id }) => {
  return (
  <svg width="374" height="375" viewBox="0 0 374 375" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1818_386" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="374" height="375">
  <path d="M187 -1.63481e-05L-2.26597e-06 375L374 375L187 -1.63481e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1818_386)">
  <rect x="1.00098" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="37.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="73.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="109.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="145.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="181.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="217.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="253.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="289.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="325.001" y="-792.997" width="12" height="1961" fill={fill}/>
  <rect x="361.001" y="-792.997" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Triangle_15;
