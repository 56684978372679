import { ShapePosition } from '../components/ShapePosition';
import { Shape } from '../components/Shape';
import { User } from '../components/User';
import { hashCode } from './StringUtils';
import { ApplicationConfigFactory, IApplicationConfig } from './IApplicationConfig';
import { IConfig } from './IConfig';
import { IEnvironmentConfig } from './IEnvironmentConfig';
import { ShapeColors } from '../components/ShapeColors';

const APP_KEY = "APP_SHARED_STORAGE";
const urlPath = window.location.pathname;
const pathSegments = urlPath.split('/');
const configParam = pathSegments[1];

const defaultSettings: IApplicationConfig = {
    configDate: "",
    folderName: "",
    timer: 300, // seconds, 5 min
    stripesWidths: [0.2, 0.5, 0.8, 1, 1.5],
    stripesWidth: 0.8,
    shapePositions: [
        { id: 0, left: (400 - 150), top: 280 },
        { id: 1, left: 400, top: 280 },
        { id: 2, left: (400 + 150), top: 280 },
    ],
    stripeColors: ["#3366FF", "#CC6666", "#4FFF4F"],
    selectedLanguage: 'zh',
    shapeRotationDegree: {
        left: 0.25,
        right: 0.25
    },
    maxShapeRotationDegree: 7,
    // name, file name, title, tag name, r, rx, ry, cx, cy, width, height, rotate, defaultRotate
    shapePalette: [
        Shape.create("rhombus", "rhombus.svg", "rhombus", "rhombus", 0, 0, 0, 0, 0, 426, 426, 45, 45, "#000000"),
        Shape.create("rhombus-small", "rhombus-small.svg", "rhombusSmall", "rhombus", 0, 0, 0, 0, 0, 207, 207, 45, 45, "#000000"),
        Shape.create("triangle", "triangle.svg", "triangle", "triangle", 0, 0, 0, 0, 0, 398, 399, 0, 0, "#000000"),
        Shape.create("triangle-small", "triangle-small.svg", "triangleSmall", "triangle", 0, 0, 0, 0, 0, 243, 244, 0, 0, "#000000"),
        Shape.create("inverted-triangle", "inverted-triangle.svg", "invertedTriangle", "triangle", 0, 0, 0, 0, 0, 372, 373, 180, 180, "#000000"),
        Shape.create("inverted-triangle-small", "inverted-triangle-small.svg", "invertedTriangleSmall", "triangle", 0, 0, 0, 0, 0, 233, 234, 180, 180, "#000000"),
        Shape.create("rectangle", "rectangle.svg", "rectangle", "rect", 0, 0, 0, 0, 0, 368, 173, 0, 0, "#000000"),
        Shape.create("rectangle-small", "rectangle-small.svg", "rectangleSmall", "rect", 0, 0, 0, 0, 0, 200, 93, 0, 0, "#000000"),
        Shape.create("square", "square.svg", "square", "rect", 0, 0, 0, 0, 0, 344, 344, 0, 0, "#000000"),
        Shape.create("square-small", "square-small.svg", "squareSmall", "rect", 0, 0, 0, 0, 0, 200, 200, 0, 0, "#000000"),
        Shape.create("circle", "circle.svg", "circle", "circle", 150, 0, 0, 150, 150, 447, 447, 0, 0, "#000000"),
        Shape.create("circle-small", "circle-small.svg", "circleSmall", "circle", 75, 0, 0, 75, 75, 235, 233, 0, 0, "#000000"),
        Shape.create("ellipse", "ellipse.svg", "ellipse", "ellipse", 0, 200, 100, 200, 100, 476, 352, 0, 0, "#000000"),
        Shape.create("ellipse-small", "ellipse-small.svg", "ellipseSmall", "ellipse", 0, 100, 50, 100, 50, 217, 160, 0, 0, "#000000"),
        Shape.create("hidden", "hidden.png", "hide", "hidden", 0, 100, 50, 100, 50, 200, 100, 0, 0, "#000000", true),
    ],
    initShapes: [
        Shape.create("rhombus", "rhombus.svg", "rhombus", "hidden", 0, 0, 0, 0, 0, 414, 414, 45, 45, "#000000", true),
        Shape.create("rhombus", "rhombus.svg", "rhombus", "rhombus", 0, 0, 0, 0, 0, 414, 414, 45, 45, "#000000"),
        Shape.create("rhombus", "rhombus.svg", "rhombus", "hidden", 0, 0, 0, 0, 0, 414, 414, 45, 45, "#000000", true),
    ],
    users: [
        User.create(false, 1, "admin", "admin", -685644176, new Date().getTime()),
        User.create(false, 2, "user", "user", -147178873, new Date().getTime())
    ],
    logoPdfFileName: "",
    workspaceDimensions: {
        width: 800,
        height: 560
    },
    startLogoLeft: {
        hide: false,
        src: "",
        name: ""
    },
    startLogoRight: {
        hide: false,
        src: "",
        name: ""
    },
    artworkLogoLeft: {
        hide: false,
        src: "",
        name: ""
    },
    artworkLogoRight: {
        hide: false,
        src: "",
        name: ""
    },
    hideIcon: {
        print: false,
        share: true,
        qrcode: false,
        demo: false
    }
}

// Use loadStorage & saveStorage to operate on user state 
// Please hardcode the user data - it's easier to manage by client & us if user state is locally
// I will secure localstorage with some simple coding algorithm 
const loadStorage = (loadDefault: boolean) => {
    const localStorageKey = `config_${configParam}`;
    let appString = localStorage.getItem(localStorageKey);
    let app = defaultSettings;
    if (appString && loadDefault === false) {
        app = ApplicationConfigFactory.parseFromJson(appString, defaultSettings);
    }
    return app;
};

const saveStorage = (app: IApplicationConfig, configName: string) => {
    const localStorageKey = `config_${configName}`;
    const appString = JSON.stringify(app);
    localStorage.setItem(localStorageKey, appString);
};

const getDefaultAppConfig = (): IApplicationConfig => {
    return loadStorage(true);
}

const resetAppConfig = () => {
    saveStorage(getDefaultAppConfig(), configParam);
}

const loadConfig = (): IConfig => {
    let env: IEnvironmentConfig = (window as any).config;
    let app = loadStorage(false);

    return { environment: env, application: app };
}

const loginUser = (username: string, password: string): { status: boolean, textStatus: string, user: User } => {
    let result = { status: false, textStatus: "incorrectLoginDetails", user: User.create(false) };

    if (username.length > 0 && password.length > 0) {
        const appConfig = loadStorage(false);

        appConfig.users.some((user: User) => {
            if (user.name === username && hashCode(password) === user.passHash) {
                user.signIn();
                result = { status: true, textStatus: "success", user };
                return true;
            }
            return false;
        });
    } else {
        result.textStatus = "pleaseEnterLoginDetails";
    }

    return result;
}

const logoutUser = (user: User): boolean => {
    if (user.id !== undefined) {
        user.signOut();
        return true;
    } else {
        return false;
    }
}

const getDefaultStripesWidthIndex = (array: number[], value: number): number => {
    return array.indexOf(value);
}

const getDefaultShapePositions = (defaultShapePositions: any): ShapePosition[] => {
    return [
        new ShapePosition(defaultShapePositions[0].id, defaultShapePositions[0].left, defaultShapePositions[0].top),
        new ShapePosition(defaultShapePositions[1].id, defaultShapePositions[1].left, defaultShapePositions[1].top),
        new ShapePosition(defaultShapePositions[2].id, defaultShapePositions[2].left, defaultShapePositions[2].top)
    ];
}

const getDefaultShapeColors = (defaultShapeColors: any): ShapeColors => {
    return new ShapeColors(defaultShapeColors);
}

const getShapePalette = (shapePaletteProps: any): Shape[] => {
    let tempShapePalette: Shape[] = [];

    shapePaletteProps.forEach((shape: any, i: number) => {
        tempShapePalette[i] = Shape.create(shape.name, shape.fileName, shape.title, shape.tagName, shape.r, shape.rx, shape.ry, shape.cx, shape.cy, shape.width, shape.height, shape.rotate, shape.defaultRotate, shape.fill);
    });

    return tempShapePalette;
}

const getInitShapes = (initShapeProps: any): Shape[] => {
    let tempInitShapes: Shape[] = [];

    initShapeProps.forEach((shape: any, i: number) => {
        tempInitShapes[i] = Shape.create(shape.name, shape.fileName, shape.title, shape.tagName, shape.r, shape.rx, shape.ry, shape.cx, shape.cy, shape.width, shape.height, shape.rotate, shape.defaultRotate, shape.fill);
    });

    return tempInitShapes;
}

export { loadConfig, getDefaultStripesWidthIndex, getDefaultShapePositions, getShapePalette, getInitShapes, loginUser, logoutUser, saveStorage, resetAppConfig, getDefaultAppConfig, getDefaultShapeColors }