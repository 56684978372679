import React from 'react';

interface IEllipseSmall_05 {
    fill: string;
    id?: string;
}

const EllipseSmall_05: React.FC<IEllipseSmall_05> = ({ fill, id }) => {
  return (
  <svg width="218" height="160" viewBox="0 0 218 160" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_2422" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="218" height="160">
  <ellipse cx="109" cy="80" rx="109" ry="80" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_2422)">
  <rect x="-1" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="11" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="23" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="35" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="47" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="59" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="71" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="83" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="95" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="107" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="119" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="131" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="143" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="155" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="167" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="179" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="191" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="203" y="-556" width="4" height="1272" fill={fill}/>
  <rect x="215" y="-556" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default EllipseSmall_05;
