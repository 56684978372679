import React from 'react';

interface ISquareSmall_08 {
    fill: string;
    id?: string;
}

const SquareSmall_08: React.FC<ISquareSmall_08> = ({ fill, id }) => {
  return (
  <svg width="186" height="186" viewBox="0 0 186 186" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_796" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="186" height="186">
  <path d="M0 186H186V-2.14577e-06H0V186Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_796)">
  <rect y="-635" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-635" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-635" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default SquareSmall_08;
