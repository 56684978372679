import React from 'react';

interface IInvertedTriangle_10 {
    fill: string;
    id?: string;
}

const InvertedTriangle_10: React.FC<IInvertedTriangle_10> = ({ fill, id }) => {
  return (
  <svg width="392" height="393" viewBox="0 0 392 393" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1087" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="392" height="393">
  <path d="M196 393L392 -8.49608e-06L0 -8.49608e-06L196 393Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1087)">
  <rect y="-522" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="360" y="-522" width="8" height="1437" fill={fill}/>
  <rect x="384" y="-522" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangle_10;
