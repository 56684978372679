import React from 'react';

interface ICircle_10 {
    fill: string;
    id?: string;
}

const Circle_10: React.FC<ICircle_10> = ({ fill, id }) => {
  return (
  <svg width="440" height="440" viewBox="0 0 440 440" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1304" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="440" height="440">
  <circle cx="220" cy="220" r="220" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1304)">
  <rect y="-499" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="360" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="384" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="408" y="-499" width="8" height="1437" fill={fill}/>
  <rect x="432" y="-499" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Circle_10;
