import React from 'react';

interface ITriangleSmall_08 {
    fill: string;
    id?: string;
}

const TriangleSmall_08: React.FC<ITriangleSmall_08> = ({ fill, id }) => {
  return (
  <svg width="258" height="262" viewBox="0 0 258 262" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_748" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="258" height="262">
  <path d="M129 -1.12775e-05L1.88185e-05 262L258 262L129 -1.12775e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_748)">
  <rect y="-597" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="234" y="-597" width="6" height="1456" fill={fill}/>
  <rect x="252" y="-597" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default TriangleSmall_08;
