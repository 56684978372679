import React from 'react';

interface IRectangle_05 {
    fill: string;
    id?: string;
}

const Rectangle_05: React.FC<IRectangle_05> = ({ fill, id }) => {
  return (
  <svg width="368" height="173" viewBox="0 0 368 173" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1814_625" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="368" height="173">
  <path d="M0 173H368V0H0V173Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1814_625)">
  <rect x="2" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="14" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="26" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="38" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="50" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="62" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="74" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="86" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="98" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="110" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="122" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="134" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="146" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="158" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="170" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="182" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="194" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="206" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="218" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="230" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="242" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="254" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="266" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="278" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="290" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="302" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="314" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="326" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="338" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="350" y="-549" width="4" height="1272" fill={fill}/>
  <rect x="362" y="-549" width="4" height="1272" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rectangle_05;
