import React from 'react';

interface IRectangle_10 {
    fill: string;
    id?: string;
}

const Rectangle_10: React.FC<IRectangle_10> = ({ fill, id }) => {
  return (
  <svg width="368" height="173" viewBox="0 0 368 173" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1232" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="368" height="173">
  <path d="M0 173H368V-4.52995e-06H0V173Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1232)">
  <rect x="12" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="36" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="60" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="84" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="108" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="132" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="156" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="180" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="204" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="228" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="252" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="276" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="300" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="324" y="-632" width="8" height="1437" fill={fill}/>
  <rect x="348" y="-632" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rectangle_10;
