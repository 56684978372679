import React from 'react';

interface ITriangle_10 {
    fill: string;
    id?: string;
}

const Triangle_10: React.FC<ITriangle_10> = ({ fill, id }) => {
  return (
  <svg width="392" height="399" viewBox="0 0 392 399" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1124" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="392" height="399">
  <path d="M196 -1.71349e-05L-1.15167e-05 399L392 399L196 -1.71349e-05Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1124)">
  <rect y="-519" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="264" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="288" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="312" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="336" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="360" y="-519" width="8" height="1437" fill={fill}/>
  <rect x="384" y="-519" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Triangle_10;
