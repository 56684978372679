import React from 'react';

interface IRhombus_15 {
    fill: string;
    id?: string;
}

const Rhombus_15: React.FC<IRhombus_15> = ({ fill, id }) => {
  return (
  <svg width="444" height="444" viewBox="0 0 444 444" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1818_349" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="445" height="445">
  <path d="M0.00146484 222.002L222.002 444.002L444.002 222.002L222.002 0.00162705L0.00146484 222.002Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1818_349)">
  <rect x="-0.000488281" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="35.9995" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="71.9995" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="108" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="144" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="180" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="216" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="252" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="288" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="324" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="360" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="396" y="-757.991" width="12" height="1961" fill={fill}/>
  <rect x="432" y="-757.991" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Rhombus_15;
