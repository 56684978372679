import React from 'react';

interface ICircleSmall_10 {
  fill: string;
  id?: string;
}

const CircleSmall_10: React.FC<ICircleSmall_10> = ({ fill, id }) => {
  return (
  <svg width="248" height="246" viewBox="0 0 248 246" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1557" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="248" height="246">
  <ellipse cx="124" cy="123" rx="124" ry="123" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1557)">
  <rect y="-596" width="8" height="1437" fill={fill}/>
  <rect x="24" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="48" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="72" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="96" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="120" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="144" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="168" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="192" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="216" y="-596" width="8" height="1437" fill={fill}/>
  <rect x="240" y="-596" width="8" height="1437" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default CircleSmall_10;
