import React from 'react';

interface ISquareSmall_15 {
    fill: string;
    id?: string;
}

  const SquareSmall_15: React.FC<ISquareSmall_15> = ({ fill, id }) => {
  return (
  <svg width="181" height="180" viewBox="0 0 181 180" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1825_1883" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="181" height="180">
  <path d="M0 180H181V-5.00679e-06H0V180Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1825_1883)">
  <rect x="12" y="-890" width="12" height="1961" fill={fill}/>
  <rect x="48" y="-890" width="12" height="1961" fill={fill}/>
  <rect x="84" y="-890" width="12" height="1961" fill={fill}/>
  <rect x="120" y="-890" width="12" height="1961" fill={fill}/>
  <rect x="156" y="-890" width="12" height="1961" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default SquareSmall_15;
