import React from 'react';

interface IInvertedTriangle_02 {
    fill: string;
    id?: string;
}

const InvertedTriangle_02: React.FC<IInvertedTriangle_02> = ({ fill, id }) => {
  return (
  <svg width="372" height="373" viewBox="0 0 372 373" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1771_707" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="372" height="373">
  <path d="M186 373L1.07597e-06 -9.01012e-07L372 3.16203e-05L186 373Z" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1771_707)">
  <rect x="89.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="41.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="137.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="17.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="113.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="65.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="161.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="5.00098" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="101.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="53.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="149.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="29.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="125.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="77.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="173.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="-0.999023" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="95.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="47.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="143.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="23.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="119.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="71.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="167.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="11.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="107.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="59.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="155.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="35.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="131.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="83.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="179.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="185.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="281.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="233.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="329.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="209.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="305.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="257.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="353.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="197.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="293.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="245.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="341.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="221.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="317.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="269.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="365.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="191.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="287.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="239.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="335.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="215.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="311.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="263.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="359.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="203.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="299.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="251.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="347.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="227.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="323.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="275.001" y="-526" width="2" height="1426" fill={fill}/>
  <rect x="371.001" y="-526" width="2" height="1426" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default InvertedTriangle_02;
