import React from 'react';

interface IEllipse_08 {
    fill: string;
    id?: string;
}

const Ellipse_08: React.FC<IEllipse_08> = ({ fill, id }) => {
  return (
  <svg width="474" height="350" viewBox="0 0 474 350" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_1824_601" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="474" height="350">
  <ellipse cx="237" cy="175" rx="237" ry="175" fill={fill}/>
  </mask>
  <g mask="url(#mask0_1824_601)">
  <rect y="-553" width="6" height="1456" fill={fill}/>
  <rect x="18" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="36" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="54" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="72" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="90" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="108" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="126" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="144" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="162" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="180" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="198" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="216" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="234" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="252" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="270" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="288" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="306" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="324" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="342" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="360" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="378" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="396" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="414" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="432" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="450" y="-553" width="6" height="1456" fill={fill}/>
  <rect x="468" y="-553" width="6" height="1456" fill={fill}/>
  </g>
  </svg>
  );
  };
  export default Ellipse_08;
